.review__card {
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.reviews {
    display: grid;
    gap: 1rem;
    grid-auto-flow: column;
    grid-auto-columns: 24%;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    padding-block: 1rem;
    margin-bottom: 2rem;
    padding-left: 5px;
}

.reviews::-webkit-scrollbar {
    width: 10px;
}

.reviews::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    outline: 1px solid slategrey;
    outline-offset: 2px;
}

.reviews::-webkit-scrollbar-thumb {
    background-color: #1941AF;
}

@media screen and (max-width:900px) {
    .reviews {
        grid-auto-columns: 90%;
        padding-inline: 0.9rem;
    }
}