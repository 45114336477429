.workouts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin-top: 2rem;
}

.workout {
    padding: 1rem;
    border: 1px solid #0652E0;
    border-radius: 4px;
    /* background-color: #0652e017; */
}

.workout-heading {
    margin-bottom: 0.2rem;
    text-align: center;
    margin-top: 2rem;
}

.workout-para {
    width: 50%;
    margin-inline: auto;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
    color: rgb(8, 8, 8);
}

.workout:nth-child(even) {
    padding: 1rem;
    border: 1px solid #0652E0;
    color: white;
    border-radius: 4px;
    /* background-color: #0652e017; */
    background-image: linear-gradient(110deg, #0C2E9E, #3C76DD);

}

.workout:nth-child(even) .icon {
    color: #0652E0;
    background: white;
}

.workout:nth-child(even) .secondary__button {
    color: white;
}

.workout:nth-child(even) .secondary__button::after {
    background: white;
}


.secondary__button {
    /* color: rgb(8, 8, 8); */
    font-weight: 600;
    background-color: transparent;
    border: 0px;
    position: relative;
    font-size: 14px;
    margin-top: 3rem;
    padding-inline: 2px;
    /* transform: opacity 500ms; */
}

.secondary__button::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -5px;
    background-color: rgb(0, 0, 0);
}

.secondary__button:hover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    opacity: 0;
    transition: all 100ms ease-out;
    bottom: -12px;
}

.icon {
    padding: 8px;
    font-size: 40px;
    color: white;
    background-image: linear-gradient(110deg, #0C2E9E, #3C76DD);
    border-radius: 4px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

@media screen and (max-width:620px) {
    .workouts {
        grid-template-columns: 1fr;
    }

    .workout-para {
        width: 100%;
    }
}