.footer {
    display: flex;
    padding-block: 2rem;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.footer__container {
    background-image: linear-gradient(110deg, #0C2E9E, #3C76DD, #0C2E9E);
}


.footer__column {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 400px;
    gap: 2rem;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.767);
    padding-bottom: 2rem;
}

.footer__column ul {
    list-style: none;
}

.footer__column li:first-child {
    color: rgb(255, 255, 255);
    font-weight: 500;
}

.footer__column li a {
    text-decoration: none;
}

.copyright__warning {
    border-top: 1px solid rgba(255, 255, 255, 0.212);
    color: white;
    padding-bottom: 0.7rem;
    font-size: 15px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: auto;
    padding-top: 0.5rem;
}

.copyright__warning p {
    text-align: center;
    margin-inline: auto;
}

@media screen and (max-width:620px) {
    .footer__column {
        grid-template-columns: 1fr;
    }
}