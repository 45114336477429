.blogs div {
    margin-block: 2rem;
    padding: 1rem;
    background-color: aliceblue;
    border-radius: 20px;
}

.blogs__container h2 {
    font-size: 30px;
    text-align: center;
    margin-top: 2rem;
}