.errorpage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.errorpage img {
    width: 50%;
}

.primary__button {
    text-decoration: none;
    margin-block: 2rem;
}