.nav__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.432); */
    background: transparent;
    position: relative;
}

.nav__logo {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 26px;
    margin-block: 0.9rem;
    font-family: 'Syne', sans-serif;
}


/* Extra Style Starts */


.nav__link {
    color: white;
    font-weight: 500;
    background-color: transparent;
    border: 0px;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    padding-inline: 2px;
    margin-inline: 0.5rem;
    /* transform: opacity 500ms; */
}

.nav__link.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -6px;
    background-color: rgb(255, 255, 255);
}

.nav__link:hover::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    transition: all 100ms ease-in;
    left: 0;
    bottom: -8px;
    background-color: rgb(255, 255, 255);
}

/* Extra Style Ends */

.blur__button {
    font-family: 'Poppins', sans-serif;
    border: 0px solid white;
    background-color: rgba(0, 0, 0, 0.6);
    background: blur(10px);
    padding: 6px 30px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    font-weight: 600;
}

.blur__button:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
}

.menu-icon {
    display: none;
}

.nav__items {
    display: block;
    margin-right: 2rem;
}

.active__nav {
    display: block !important;
}

.menu__icons {
    z-index: 102;
    display: none;
}


@media screen and (max-width:950px) {

    .nav__items {
        position: fixed;
        top: 60px;
        left: 0;
        z-index: 100;
        display: none;
        width: 100%;
    }

    .nav__link {
        display: block;
        z-index: 100;
        margin-bottom: 0.8rem;
    }


    .nav {
        background-color: rgb(0, 0, 0);
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100vw;
    }

    .nav__logo {
        z-index: 101;
    }

    .blur__button {
        display: none;
        width: 100%;
    }

    .menu__icons {
        display: block;
    }

    .menu__icon {
        color: white;
        font-size: 30px;
    }

    .nav__buttons {
        margin-top: 100%;
        text-align: center;
        margin-inline: auto;
    }

}