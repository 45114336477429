@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Syne:wght@800&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

button {
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.container {
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 8px;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width:620px) {
  .container {
    padding-inline: 16px;
  }
}